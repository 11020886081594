<template>
  <v-card>
    <v-card-text>
      <v-row cols="12">
        <v-col cols="12" xl="12" lg="12" md="12">
          <v-switch
            class="mt-1"
            dense
            inset
            color="green"
            label="開啟出貨單位"
            v-model="shippingUnit"
          ></v-switch>
        </v-col>


        <v-col cols="12" :lg="shippingUnit ? 4 : 12" :md="shippingUnit ? 4 : 12" :sm="shippingUnit ? 4 : 12" class="pa-4 d-none d-sm-flex">
          <div class="title mb-4">計價單位</div>
        </v-col>
        <v-col v-if="shippingUnit" cols="12" lg="4" md="4" sm="4" class="pa-4 d-none d-sm-flex"></v-col>
        <v-col v-if="shippingUnit" cols="12" lg="4" md="4" sm="4" class="pa-4 d-none d-sm-flex">
          <div class="title mb-4">出貨單位</div>
        </v-col>

        <v-col cols="12" lg="4" md="4" sm="4" xs="12" class="pa-4">
          <div class="pa-4 elevation-6">
            <div class="title mb-4 d-xs-flex d-sm-none d-md-none d-xl-none">
              計價單位
            </div>
            <v-select
              label="單位名稱"
              :items="unitList"
              class="mb-4"
              dense
              outlined
              v-model="formData.calculate_unit"
            ></v-select>

            <v-text-field
              label="單價"
              class="mb-4"
              dense
              outlined
              v-model="formData.price"
              maxlength="6"
            ></v-text-field>
          </div>
        </v-col>

        <v-col v-if="shippingUnit" cols="12" lg="1" md="1" sm="1" xs="12" class="d-flex align-center justify-center">
          <div>X</div>
        </v-col>

        <v-col v-if="shippingUnit" cols="12" lg="2" md="2" sm="2" xs="12" class="d-flex align-center justify-center">
          <div class="pa-0">
            <v-text-field
              label="數量"
              hide-details
              dense
              outlined
              v-model="formData.per_shipping_count"
              maxlength="3"
              :disabled="formData.calculate_unit == formData.shipping_unit"
            ></v-text-field>
          </div>
        </v-col>

        <v-col v-if="shippingUnit" cols="12" lg="1" md="1" sm="1" xs="12" class="d-flex align-center justify-center py-0 my-0">
          <div>＝</div>
        </v-col>

        <v-col v-if="shippingUnit" cols="12" lg="4" md="4" sm="4" xs="12" class="pa-4">
          <div class="pa-4 elevation-6">
            <div class="title mb-4 d-xs-flex d-sm-none d-md-none d-xl-none">
              出貨單位
            </div>
            <v-select
              label="單位名稱"
              :items="unitList"
              class="mb-4"
              dense
              outlined
              v-model="formData.shipping_unit"
            ></v-select>

            <div class="d-none d-sm-flex">
              <br><br>
            </div>

            <div class="pl-2 title" v-if="calculateResult">
              出貨價格: {{ calculateResult }} 元
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script type="text/babel">
import formItemMixin from '@/components/form/mixins/formItemMixin'
export default {
  mixins: [formItemMixin],
  data() {
    return {
      shippingUnit: true
    }
  },
  computed: {
    unitList() {
      return this.formMeta.productUnits.map((unit) => (unit.name)) || []
    },
    calculateResult() {
      if (!this.formData.price) return null
      if (!this.formData.per_shipping_count) return null
      return this.formData.per_shipping_count * this.formData.price
    },
  },
  watch: {
    'formData.calculate_unit': {
      immediate: true,
      handler() {
        if (this.formData.calculate_unit == this.formData.shipping_unit && this.formData.per_shipping_count != 1) {
          this.formData.per_shipping_count = 1
        }
      }
    },
    'formData.shipping_unit': {
      immediate: true,
      handler() {
        if (this.formData.calculate_unit == this.formData.shipping_unit && this.formData.per_shipping_count != 1) {
          this.formData.per_shipping_count = 1
        }
      }
    },
    'shippingUnit': {
      immediate: true,
      handler(newVal) {
        if (newVal === false) {
          this.formData.per_shipping_count = 1
          this.formData.shipping_unit = this.formData.calculate_unit
        }
      }
    },
  }
}
</script>